import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-knoxville-tennessee.png'
import image0 from "../../images/cities/scale-model-of-knoxville-walking-tours-in-knoxville-tennessee.png"
import image1 from "../../images/cities/scale-model-of-knoxville-food-tours-in-knoxville-tennessee.png"
import image2 from "../../images/cities/scale-model-of-haunted-knoxville-ghost-tours-in-knoxville-tennessee.png"
import image3 from "../../images/cities/scale-model-of-rowing-man-statue-in-knoxville,-tn-in-knoxville-tennessee.png"
import image4 from "../../images/cities/scale-model-of-charles-krutch-park-in-knoxville-tennessee.png"
import image5 from "../../images/cities/scale-model-of-east-tennessee-historical-society-and-museum-in-knoxville-tennessee.png"
import image6 from "../../images/cities/scale-model-of-blount-mansion-in-knoxville-tennessee.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Knoxville'
            state='Tennessee'
            image={image}
            lat='35.9707'
            lon='-83.9493'
            attractions={ [{"name": "Knoxville Walking Tours", "vicinity": "301 S Gay St, Knoxville", "types": ["point_of_interest", "establishment"], "lat": 35.967034, "lng": -83.91954899999996}, {"name": "Knoxville Food Tours", "vicinity": "402 S Gay St, Knoxville", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 35.9662789, "lng": -83.9184692}, {"name": "Haunted Knoxville Ghost Tours", "vicinity": "1404, 36 Market Square, Knoxville", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 35.965963, "lng": -83.91955289999999}, {"name": "Rowing Man Statue in Knoxville, TN", "vicinity": "W. Church Avenue &, S Gay St, Knoxville", "types": ["point_of_interest", "establishment"], "lat": 35.9634632, "lng": -83.91723489999998}, {"name": "Charles Krutch Park", "vicinity": "504 Market St, Knoxville", "types": ["park", "point_of_interest", "establishment"], "lat": 35.964356, "lng": -83.91857270000003}, {"name": "East Tennessee Historical Society and Museum", "vicinity": "601 S Gay St, Knoxville", "types": ["museum", "store", "point_of_interest", "establishment"], "lat": 35.9641098, "lng": -83.91776650000003}, {"name": "Blount Mansion", "vicinity": "200 W Hill Ave, Knoxville", "types": ["museum", "point_of_interest", "establishment"], "lat": 35.9610482, "lng": -83.91476219999998}] }
            attractionImages={ {"Knoxville Walking Tours":image0,"Knoxville Food Tours":image1,"Haunted Knoxville Ghost Tours":image2,"Rowing Man Statue in Knoxville, TN":image3,"Charles Krutch Park":image4,"East Tennessee Historical Society and Museum":image5,"Blount Mansion":image6,} }
       />);
  }
}